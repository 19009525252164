import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '@env/environment';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxAppVersionDirective } from 'ngx-app-version';
import { NgxUpdateAppDirective } from 'ngx-update-app';
import { HtmlLangDirective } from './shared/directives/html-lang.directive';
import { TranslocoRevealDirective } from './shared/directives/transloco-reveal.directive';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TranslocoModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NgxAppVersionDirective, NgxUpdateAppDirective, TranslocoRevealDirective, HtmlLangDirective],
})
export class AppComponent {
  public title = environment.name;
}
