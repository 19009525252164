import { Routes } from '@angular/router';
import { itemsLayoutGuard } from '@app/shared/guards/items-layout.guard';
import { ROUTE_DEFINITION } from '../shared/constants/route-definition.constant';
import { PageSection } from '../shared/dto/page.dto';
import { titleResolverFactory } from '../shared/factories/title.factory';

const section: PageSection[] = ['equipment', 'vehicle-control'];

export const equipmentVehicleColntrolRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section,
      action: ['list'],
    }),
    loadComponent: () => import('./equipment-vehicle-control-list/equipment-vehicle-control-list.component'),
    children: [
      {
        path: ROUTE_DEFINITION.ROOT.TABLE,
        canActivate: [itemsLayoutGuard],
        loadComponent: () =>
          import('./equipment-vehicle-control-list-table/equipment-vehicle-control-list-table.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.TILES,
        loadComponent: () =>
          import('./equipment-vehicle-control-list-tiles/equipment-vehicle-control-list-tiles.component'),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ROUTE_DEFINITION.ROOT.TABLE,
      },
    ],
  },
  {
    path: ':id',
    loadComponent: () =>
      import('./equipment-vehicle-control-detail-root/equipment-vehicle-control-detail-root.component'),
    children: [
      {
        path: '',
        title: titleResolverFactory({
          section,
          action: ['detail'],
          title: 'id',
        }),
        loadComponent: () => import('./equipment-vehicle-control-detail/equipment-vehicle-control-detail.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.ACTIVITY,
        title: titleResolverFactory({
          section,
          action: ['activity'],
          title: 'id',
        }),
        loadComponent: () => import('../activity/activity.component'),
      },
    ],
  },
];
