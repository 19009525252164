import { inject } from '@angular/core';
import { CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { ModuleLimitsFragment } from '@graphql/fragments/me.apollo';
import { MeGQL } from '@graphql/query/root/generated/me.apollo';
import { map } from 'rxjs';
import { ROUTE_DEFINITION } from '../constants/route-definition.constant';

export const moduleLimitsGuardFactory = (module: keyof Omit<ModuleLimitsFragment, '__typename'>): CanActivateFn => {
  return () => {
    const redirectUrl = inject(Router).createUrlTree(['/', ROUTE_DEFINITION.APP.RESTRICTED]);

    return inject(MeGQL)
      .fetch({}, { fetchPolicy: 'cache-first' })
      .pipe(
        map((res) => res.data.me?.activeTenant?.moduleLimits[module] || false),
        map((isActive) => isActive || new RedirectCommand(redirectUrl, { skipLocationChange: false })),
      );
  };
};
