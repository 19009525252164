import { Route, Routes } from '@angular/router';
import { notLoggedGuard } from '@app/shared/guards/not-logged.guard';
import { ROUTE_DEFINITION } from '../shared/constants/route-definition.constant';
import { titleResolverFactory } from '../shared/factories/title.factory';

const createRootRoute = (path: string, route: Route): Route => {
  return {
    path,
    canActivate: [notLoggedGuard],
    loadComponent: () => import('../layout/layout-public/layout-public.component'),
    children: [route],
  };
};

export const rootRoutes: Routes = [
  createRootRoute(ROUTE_DEFINITION.APP.LOGIN, {
    path: '',
    title: titleResolverFactory({
      section: ['login'],
    }),
    loadComponent: () => import('./login/login.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.REGISTER, {
    path: '',
    title: titleResolverFactory({
      section: ['register'],
    }),
    loadComponent: () => import('./register/register.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.REGISTER_COMPANY, {
    path: '',
    title: titleResolverFactory({
      section: ['register-company'],
    }),
    loadComponent: () => import('./register-company/register-company.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.RESET_PASSWORD, {
    path: '',
    title: titleResolverFactory({
      section: ['reset-password'],
    }),
    loadComponent: () => import('./reset-password/reset-password.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.FORGOTTEN_PASSWORD, {
    path: '',
    title: titleResolverFactory({
      section: ['forgotten-password'],
    }),
    loadComponent: () => import('./forgotten-password/forgotten-password.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.ACTIVATION, {
    path: '',
    title: titleResolverFactory({
      section: ['activation'],
    }),
    loadComponent: () => import('./activation/activation.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.JOIN, {
    path: '',
    title: titleResolverFactory({
      section: ['join'],
    }),
    loadComponent: () => import('./join/join.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.TWO_FACTOR, {
    path: '',
    title: titleResolverFactory({
      section: ['two-factor'],
    }),
    loadComponent: () => import('./login-two-factor/login-two-factor.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.EMAIL_FACTOR, {
    path: '',
    title: titleResolverFactory({
      section: ['two-factor'],
    }),
    loadComponent: () => import('./login-email-factor/login-email-factor.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.BACKUP_CODE, {
    path: '',
    title: titleResolverFactory({
      section: ['backup-code'],
    }),
    loadComponent: () => import('./login-backup-code/login-backup-code.component'),
  }),
];
