import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { provideFilters } from '@ui';

export const FILTERS_PROVIDER = provideFilters({
  i18n: {
    $translate: true,
    search: {
      label: _('uni.search'),
      placeholder: _('uni.search-placeholder'),
    },
    filters: {
      open: _('ui.filters.open'),
      reset: _('ui.filters.reset'),
      apply: _('ui.filters.apply'),
      close: _('ui.filters.close'),
      header: _('ui.filters.header'),
    },
  },
});
