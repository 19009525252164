import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { provideButton } from '@ui';

export const BUTTON_PROVIDER = provideButton({
  i18n: {
    $translate: true,
    loading: _('uni.loading'),
    actionButtonTitle: _('uni.action'),
  },
});
