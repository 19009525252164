import { HttpErrorResponse } from '@angular/common/http';
import { ApolloLink } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { TranslocoService } from '@jsverse/transloco';
import * as Sentry from '@sentry/angular-ivy';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { STORAGE } from '../constants/storage.constant';
import { AuthUtils, CustomHandleResponseFn } from './auth.utils';
import { StorageUtils } from './storage.utils';

export class ApolloUtils {
  public static tokenRefreshLink = new TokenRefreshLink({
    isTokenValidOrUndefined: (): Promise<boolean> => AuthUtils.isStorageTokenValidOrUndefined(),
    fetchAccessToken: (): Promise<Response> => AuthUtils.getRefreshedToken(),
    handleFetch: (token: string): void => AuthUtils.setAccessToken(token),
    handleResponse: (): CustomHandleResponseFn => AuthUtils.handleResponse(),
    handleError: (): Promise<void> => AuthUtils.logout(),
  });

  public static logoutWithRedirectToLogin(): void {
    AuthUtils.logout().then(() => {
      console.log('User is not authenticated. Log out...');
      // Navigate to login page. We can not use router here because we are not in Angular context.
      if (typeof window !== 'undefined') window.location.href = '/';
    });
  }

  public static authLink = (transloco: TranslocoService): ApolloLink => {
    return new ApolloLink((operation, forward) => {
      const accessToken = StorageUtils.getItem(STORAGE.ACCESS_TOKEN);
      operation.setContext({
        headers: {
          'x-authorization': AuthUtils.authHeader(accessToken),
          'Accept-language': transloco.getActiveLang(),
        },
      });
      return forward(operation);
    });
  };

  public static retryLink = new RetryLink();

  public static errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      console.log('Network error', networkError.message);
      if (networkError instanceof HttpErrorResponse && networkError?.status === 401) {
        this.logoutWithRedirectToLogin();
      }
    }
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (error.message === 'not_authenticated') {
          this.logoutWithRedirectToLogin();
        }
      });
    }
    return forward(operation);
  });

  public static sentryLink = new ApolloLink((operation, forward) => {
    Sentry.addBreadcrumb({
      category: 'graphqlRequest',
      message: 'GraphQL request',
      level: 'info',
      data: {
        query: operation.operationName,
        variables: operation.variables,
      },
    });
    return forward(operation);
  });
}
