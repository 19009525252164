import { Routes } from '@angular/router';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { BREADCRUMB_KEY } from '@ui';

const section: PageSection[] = ['settings', 'account', 'integrations'];

export const settingsAccountIntegrationsRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section: section,
    }),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    loadComponent: () => import('./settings-account-integrations/settings-account-integrations.component'),
  },
];
