import { Provider } from '@angular/core';
import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { CalendarDayOfWeek, INPUT_FILE_UPLOADER, provideInput } from '@ui';
import { FileUploadService } from '../services/file-upload.service';

export const INPUT_PROVIDER = provideInput({
  file: {
    formats: ['png', 'jpg', 'gif', 'pdf'],
    maxSize: 10,
    i18n: {
      $translate: true,
      clear: _('uni.clear'),
      dropText: _('uni.drop-file-here'),
    },
  },
  phone: {
    i18n: {
      $translate: true,
      empty: _('uni.any-phone-prefix-was-not-found'),
      loading: _('uni.loading'),
    },
    pattern: '',
  },
  chips: {
    i18n: {
      $translate: true,
      notFoundText: _('uni.no-items-found'),
      notFoundTextFor: _('uni.no-items-found-for'),
      typeToSearchText: _('uni.type-to-search'),
      addTagText: _('uni.add-item'),
      loadingText: _('uni.loading'),
      clearAllText: _('uni.clear-all'),
      removeTagText: _('uni.remove-item'),
    },
  },
  autocomplete: {
    i18n: {
      $translate: true,
      notFoundText: _('uni.no-items-found'),
      notFoundTextFor: _('uni.no-items-found-for'),
      typeToSearchText: _('uni.type-to-search'),
      addTagText: _('uni.add-item'),
      loadingText: _('uni.loading'),
      clearAllText: _('uni.clear-all'),
      removeTagText: _('uni.remove-item'),
    },
  },
  calendar: {
    i18n: {
      $translate: true,
      dayoOfWeekShort: {
        [CalendarDayOfWeek.Sunday]: _('uni.day-of-week.sunday.short'),
        [CalendarDayOfWeek.Monday]: _('uni.day-of-week.monday.short'),
        [CalendarDayOfWeek.Tuesday]: _('uni.day-of-week.tuesday.short'),
        [CalendarDayOfWeek.Wednesday]: _('uni.day-of-week.wednesday.short'),
        [CalendarDayOfWeek.Thursday]: _('uni.day-of-week.thursday.short'),
        [CalendarDayOfWeek.Friday]: _('uni.day-of-week.friday.short'),
        [CalendarDayOfWeek.Saturday]: _('uni.day-of-week.saturday.short'),
      },
      dayoOfWeekLong: {
        [CalendarDayOfWeek.Sunday]: _('uni.day-of-week.sunday.long'),
        [CalendarDayOfWeek.Monday]: _('uni.day-of-week.monday.long'),
        [CalendarDayOfWeek.Tuesday]: _('uni.day-of-week.tuesday.long'),
        [CalendarDayOfWeek.Wednesday]: _('uni.day-of-week.wednesday.long'),
        [CalendarDayOfWeek.Thursday]: _('uni.day-of-week.thursday.long'),
        [CalendarDayOfWeek.Friday]: _('uni.day-of-week.friday.long'),
        [CalendarDayOfWeek.Saturday]: _('uni.day-of-week.saturday.long'),
      },
      previousMonth: _('uni.previous-month'),
      nextMonth: _('uni.next-month'),
      today: _('uni.today'),
      clear: _('uni.clear'),
    },
    dayOfWeekOffset: 1,
    actionButtons: true,
  },
});

export const INPUT_FILE_UPLOADER_PROVIDER: Provider = {
  provide: INPUT_FILE_UPLOADER,
  useClass: FileUploadService,
};
