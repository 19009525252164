import { __extends } from "tslib";
import { ApolloLink, fromPromise } from '@apollo/client/core';
import { OperationQueuing } from './queuing';
export { OperationQueuing } from './queuing';
var throwServerError = function (response, result, message) {
  var error = new Error(message);
  error.response = response;
  error.statusCode = response.status;
  error.result = result;
  throw error;
};
var parseAndCheckResponse = function (operation, accessTokenField) {
  return function (response) {
    return response.text().then(function (bodyText) {
      if (typeof bodyText !== 'string' || !bodyText.length) {
        return bodyText || '';
      }
      try {
        return JSON.parse(bodyText);
      } catch (err) {
        var parseError = err;
        parseError.response = response;
        parseError.statusCode = response.status;
        parseError.bodyText = bodyText;
        return Promise.reject(parseError);
      }
    }).then(function (parsedBody) {
      if (response.status >= 300) {
        throwServerError(response, parsedBody, "Response not successful: Received status code ".concat(response.status));
      }
      if (!parsedBody.hasOwnProperty(accessTokenField) && parsedBody.data && !parsedBody.data.hasOwnProperty(accessTokenField) && !parsedBody.hasOwnProperty('errors')) {
        throwServerError(response, parsedBody, "Server response was missing for query '".concat(operation.operationName, "'."));
      }
      return parsedBody;
    });
  };
};
var TokenRefreshLink = function (_super) {
  __extends(TokenRefreshLink, _super);
  function TokenRefreshLink(params) {
    var _this = _super.call(this) || this;
    _this.extractToken = function (body) {
      if (body.data) {
        return body.data[_this.accessTokenField];
      }
      return body[_this.accessTokenField];
    };
    _this.accessTokenField = params.accessTokenField || 'access_token';
    _this.isTokenValidOrUndefined = params.isTokenValidOrUndefined;
    _this.fetchAccessToken = params.fetchAccessToken;
    _this.handleFetch = params.handleFetch;
    _this.handleResponse = params.handleResponse || parseAndCheckResponse;
    _this.handleError = typeof params.handleError === 'function' ? params.handleError : function (err) {
      console.error(err);
    };
    _this.fetching = false;
    _this.queue = new OperationQueuing();
    return _this;
  }
  TokenRefreshLink.prototype.request = function (operation, forward) {
    var _this = this;
    if (typeof forward !== 'function') {
      throw new Error('[Token Refresh Link]: Token Refresh Link is a non-terminating link and should not be the last in the composed chain');
    }
    return fromPromise(this.isTokenValidOrUndefined(operation).then(function (tokenValidOrUndefined) {
      if (tokenValidOrUndefined) {
        return forward(operation);
      } else {
        if (!_this.fetching) {
          _this.fetching = true;
          _this.fetchAccessToken().then(_this.handleResponse(operation, _this.accessTokenField)).then(function (body) {
            var token = _this.extractToken(body);
            if (!token) {
              throw new Error("[Token Refresh Link]: Unable to retrieve new access token");
            }
            return token;
          }).then(function (payload) {
            return _this.handleFetch(payload, operation);
          }).catch(function (error) {
            return _this.handleError(error, operation);
          }).finally(function () {
            _this.fetching = false;
            _this.queue.consumeQueue();
          });
        }
        return _this.queue.enqueueRequest({
          operation: operation,
          forward: forward
        });
      }
    })).flatMap(function (val) {
      return val;
    });
  };
  return TokenRefreshLink;
}(ApolloLink);
export { TokenRefreshLink };
