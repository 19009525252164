import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { ApplicationConfig, ErrorHandler, isDevMode, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { provideAppVersion } from 'ngx-app-version';
import { provideUpdateApp } from 'ngx-update-app';
import { VERSION } from '../environments/version';
import { routes } from './app.routes';
import { graphqlProvider } from './graphql.provider';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from './shared/constants/language.constant';
import { socialFactory } from './shared/factories/social.factory';
import { updateAppFactory } from './shared/factories/update-app.factory';
import { UI_PROVIDERS } from './shared/providers';
import { CustomErrorHandlerService } from './shared/services/custom-error-handler.service';
import { TenantDomainService } from './shared/services/tenant-domain.service';
import { TranslocoHttpLoader } from './shared/services/transloco-http-loader';
import { provideNgxPendo } from 'ngx-pendo';
import { environment } from '@env/environment';

registerLocaleData(localeCs, 'cs-CZ');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideExperimentalZonelessChangeDetection(),
    provideTransloco({
      config: {
        availableLangs: AVAILABLE_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistLang({
      storage: {
        useValue: localStorage,
      },
    }),
    provideTranslocoLocale(),
    provideAppVersion({
      version: VERSION.version,
    }),
    provideUpdateApp({
      interval: 1000 * 60, // check every minute
      dryRun: false, // to see modal on init
      onUpdateFactory: updateAppFactory,
    }),
    provideHttpClient(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideAnimations(),
    graphqlProvider,
    { provide: ErrorHandler, useClass: CustomErrorHandlerService },
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: socialFactory,
      deps: [TenantDomainService],
    },
    provideNgxPendo({
      pendoApiKey: environment.pendoApiKey,
      pendoIdFormatter: (pendoId: string) => pendoId.toLowerCase(),
    }),
    ...UI_PROVIDERS,
  ],
};
