import { isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { VERSION } from './environments/version';

const IGNORE_ERRORS = [
  'graphql/: 0 Unknown Error', // Offline, wonky connection or BE error
  "expected expression, got '<'", // Offline
  'Unexpected token <', // Bad response from BE (usually 5xx)
  "Unexpected token '<", // Bad response from BE (usually 5xx)
  '_avast_submit', // Virus :)
  // "ObservableQuery with this id doesn't exist", // store to cache pagination
  'Uncaught (in promise): Error: Loading chunk', // After release
  // 'SecurityError: The operation is insecure', // Old FF not fatal error (warning)
  // 'appzi.io', // 3rd plugin error (encapsulated)
  'ChunkLoadError: Loading chunk', // releasing new version
  // 'Non-Error promise rejection captured with keys: isTrusted', // https://github.com/getsentry/sentry-javascript/issues/2292
  'Non-Error exception captured', // https://github.com/getsentry/sentry-javascript/issues/2292 or https://github.com/getsentry/sentry-react-native/issues/391
  "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')", // embeded youtube video error
  "undefined is not an object (evaluating 'webkit.messageHandlers", // unresolved sentry error,
  // 'Uncaught (in promise): Syntax Error: Unexpected Name "undefined"', // only IE causes this issue
  // 'Syntax error' // Smartlook,
  'Illegal invocation', // gtag error
];

Sentry.init({
  // https://github.com/getsentry/sentry-javascript/issues/2210
  // beforeSend(event: Event, hint?: EventHint): Event {
  //   if (hint && (hint.originalException instanceof CustomEvent || hint.originalException instanceof Event)) {
  //     event.extra.isTrusted = hint.originalException.isTrusted;
  //     event.extra.detail = hint.originalException instanceof CustomEvent ? hint.originalException.detail : null;
  //     event.extra.type = hint.originalException.type;
  //   }
  //   return event;
  // },
  enabled: !isDevMode(),
  dsn: environment.sentryDsn,
  environment: environment.name,
  release: environment.name === 'prod' ? VERSION.version : environment.name,
  integrations: [Sentry.browserTracingIntegration()],
  autoSessionTracking: false,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  ignoreErrors: IGNORE_ERRORS,
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Moz extensions
    /^moz-extension:\/\//i,
    // Safari extension
    /^safari-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

// eslint-disable-next-line no-console
bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
