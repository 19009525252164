import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { provideChips } from '@ui';

export const CHIPS_PROVIDER = provideChips({
  color: 'blue',
  i18n: {
    $translate: true,
    ariaLabel: _('ui.chips.aria-label'),
    srOnlyLabel: _('ui.chips.sr-only-label'),
    notFound: _('ui.chips.not-found'),
  },
});
