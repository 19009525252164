import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { PageSection } from '@app/shared/dto/page.dto';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { permissionCanReadGuardFactory } from '@app/shared/guards/permission.guard';
import { PermissionSectionChoices } from '@graphql/types';
import { BREADCRUMB_KEY } from '@ui';
import { settingsUserNotificationRoutes } from './notification/routes';
import { settingsUserSecurityRoutes } from './settings-user-security/routes';

const section: PageSection[] = ['settings', 'user'];

export const settingsUserRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTE_DEFINITION.SETTINGS_USER.BASIC,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_USER.BASIC,
    title: titleResolverFactory({
      section: section,
      action: ['basic'],
    }),
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'user' }),
    },
    canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.AccountPreferencesGeneral)],
    loadComponent: () => import('./settings-user-basic/settings-user-basic.component'),
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_USER.SECURITY,
    title: titleResolverFactory({
      section: section,
      action: ['security'],
    }),
    canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.AccountPreferencesGeneral)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'security' }),
    },
    children: settingsUserSecurityRoutes,
  },
  ...settingsUserNotificationRoutes,
];
