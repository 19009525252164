import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ResponsiveService } from '@app/shared/services/responsive.service';
import { map } from 'rxjs';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';

export const itemsLayoutGuard: CanActivateFn = (_, state) => {
  const responsiveService = inject(ResponsiveService);
  const router = inject(Router);

  return responsiveService.gtSm().pipe(
    map((isSm) => {
      if (!isSm) {
        // used split because ActivatedRouteSnapshot's url contains only last segment of route e.g. 'table' or 'tiles'
        // so it won't be possible to appropriately re-route
        // this wouldn't work with 'route/route/table/:id"
        router.navigate([...state.url.split('/').slice(0, -1), ROUTE_DEFINITION.ROOT.TILES]);
      }
      return isSm;
    }),
  );
};
