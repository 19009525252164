import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { map, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PageAction, PageSection } from '../dto/page.dto';
import { TitleSourceService } from './title-source.service';

interface CustomTitleStrategyOptions {
  section: PageSection[];
  action?: PageAction[];
  title?: string;
}

@Injectable()
export class CustomTitleStrategyService extends TitleStrategy {
  private readonly siteName = environment.siteName;
  private readonly titleSource = inject(TitleSourceService);

  constructor(
    private title: Title,
    private transloco: TranslocoService,
  ) {
    super();
  }

  public updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title) {
      const parsedData = this.parseTitleData(title);

      if (parsedData.title) {
        this.titleSource.translatedTitle$
          .pipe(switchMap((remote) => this.transloco.selectTranslation().pipe(map(() => remote))))
          .subscribe((translatedTitle) => {
            this.translateTitleFromParts(parsedData, translatedTitle);
          });
      } else {
        this.transloco.selectTranslation().subscribe(() => {
          this.translateTitleFromParts(parsedData);
        });
      }
    } else {
      this.title.setTitle(this.siteName);
    }
  }

  private translateTitleFromParts(parsedData: CustomTitleStrategyOptions, translatedTitle?: string): void {
    const section = parsedData.section.map((item) => this.transloco.translate(item) as string);
    const action = parsedData?.action?.map((item) => this.transloco.translate(item) as string) || [];
    let parts = [...action, ...section, this.siteName];
    if (translatedTitle) {
      parts = [translatedTitle, ...parts];
    }
    this.title.setTitle(parts.join(' - '));
  }

  private parseTitleData(title: string): CustomTitleStrategyOptions {
    const data = JSON.parse(title);
    return {
      section: data.section,
      action: data.action,
      title: data.title,
    };
  }
}
