import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { PageSection } from '../shared/dto/page.dto';
import { titleResolverFactory } from '../shared/factories/title.factory';

const section: PageSection[] = ['education', 'trainings'];

export const documentsRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section,
      action: ['list'],
    }),
    loadComponent: () => import('./documents-root/documents-root.component'),
    children: [
      {
        path: '',
        loadComponent: () => import('./documents-internal-list/documents-internal-list.component'),
      },
      {
        path: ROUTE_DEFINITION.DOCUMENTS.MANUALS,
        loadComponent: () => import('./documents-equipment-list/documents-equipment-list.component'),
      },
    ],
  },
];
