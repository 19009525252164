import { SettingsSection } from '@app/shared/constants/settings.constants';
import { BreadcrumbsGqlOptions } from '@app/shared/factories/breadcrumb.factory';
import { NameUtils } from '@app/shared/utils/name.utils';
import {
  CertificationDetailFragment,
  CertificationDetailGQL,
  CertificationDetailQueryVariables,
} from '@graphql/query/object/generated/certification-detail.apollo';
import {
  CustomObjectPropertyDetailFragment,
  CustomObjectPropertyDetailGQL,
  CustomObjectPropertyDetailQueryVariables,
} from '@graphql/query/object/generated/custom-property-object-detail.apollo';
import {
  HealthCareProviderDetailFragment,
  HealthCareProviderDetailGQL,
  HealthCareProviderDetailQueryVariables,
} from '@graphql/query/object/generated/health-care-provider.apollo';
import {
  JobPositionDetailFragment,
  JobPositionDetailGQL,
  JobPositionDetailQueryVariables,
} from '@graphql/query/object/generated/job-position-detail.apollo';
import {
  MedicalExaminationTemplateDetailFragment,
  MedicalExaminationTemplateDetailGQL,
  MedicalExaminationTemplateDetailQueryVariables,
} from '@graphql/query/object/generated/medical-examination-template-detail.apollo';
import {
  MedicalExaminationTypeDetailFragment,
  MedicalExaminationTypeDetailGQL,
  MedicalExaminationTypeDetailQueryVariables,
} from '@graphql/query/object/generated/medical-examinations-type-detail.apollo';
import {
  ProtectiveGearTemplateDetailFragment,
  ProtectiveGearTemplateDetailGQL,
  ProtectiveGearTemplateDetailQueryVariables,
} from '@graphql/query/object/generated/protective-gear-template-detail.apollo';
import {
  ToolTemplateDetailFragment,
  ToolTemplateDetailGQL,
  ToolTemplateDetailQueryVariables,
} from '@graphql/query/object/generated/tool-template-detail.apollo';
import {
  ToolTypeDetailFragment,
  ToolTypeDetailGQL,
  ToolTypeDetailQueryVariables,
} from '@graphql/query/object/generated/tool-type-detail.apollo';
import {
  TrainingTemplateDetailFragment,
  TrainingTemplateDetailGQL,
  TrainingTemplateDetailQueryVariables,
} from '@graphql/query/object/generated/training-template-detail.apollo';
import {
  UserDetailGQL,
  UserDetailQueryVariables,
  UserFragment,
} from '@graphql/query/object/generated/user-detail.apollo';
import {
  UserRoleDetailFragment,
  UserRoleDetailGQL,
  UserRoleDetailQueryVariables,
} from '@graphql/query/object/generated/user-role-detail.apollo';
import {
  VehicleTemplateDetailFragment,
  VehicleTemplateDetailGQL,
  VehicleTemplateDetailQueryVariables,
} from '@graphql/query/object/generated/vehicle-template-detail.apollo';
import {
  WorkplaceInspectionTemplateDetailFragment,
  WorkplaceInspectionTemplateDetailGQL,
  WorkplaceInspectionTemplateDetailQueryVariables,
} from '@graphql/query/object/generated/workplace-inspection-template-detail.apollo';

type BreadcrumbsGqlDetailPages = Partial<Record<SettingsSection, BreadcrumbsGqlOptions>>;

export const BREADCRUMBS_GQL: BreadcrumbsGqlDetailPages = {
  'user': {
    gql: UserDetailGQL,
    variablesFn: (param: string): UserDetailQueryVariables => ({
      userDetailId: param,
    }),
    mapFn: (res: UserFragment) => NameUtils.nullableJoinSpace(res.firstName, res.lastName),
  },
  'tool-types': {
    gql: ToolTypeDetailGQL,
    variablesFn: (param): ToolTypeDetailQueryVariables => ({
      toolTypeDetailId: param,
    }),
    mapFn: (res: ToolTypeDetailFragment) => res.name,
  },
  'roles': {
    gql: UserRoleDetailGQL,
    variablesFn: (param): UserRoleDetailQueryVariables => ({
      userRoleDetailId: param,
    }),
    mapFn: (res: UserRoleDetailFragment) => res.name,
  },
  'custom-props': {
    gql: CustomObjectPropertyDetailGQL,
    variablesFn: (param): CustomObjectPropertyDetailQueryVariables => ({
      customObjectPropertyDetailId: param,
    }),
    mapFn: (res: CustomObjectPropertyDetailFragment) => res.label,
  },
  'job-positions': {
    gql: JobPositionDetailGQL,
    variablesFn: (param): JobPositionDetailQueryVariables => ({
      jobPositionDetailId: param,
    }),
    mapFn: (res: JobPositionDetailFragment) => res.name,
  },
  'certifications': {
    gql: CertificationDetailGQL,
    variablesFn: (param): CertificationDetailQueryVariables => ({
      certificationDetailId: param,
    }),
    mapFn: (res: CertificationDetailFragment) => res.name,
  },
  'medical-examination-types': {
    gql: MedicalExaminationTypeDetailGQL,
    variablesFn: (param): MedicalExaminationTypeDetailQueryVariables => ({
      medicalExaminationTypeDetailId: param,
    }),
    mapFn: (res: MedicalExaminationTypeDetailFragment) => res.name,
  },
  'health-care-providers': {
    gql: HealthCareProviderDetailGQL,
    variablesFn: (param): HealthCareProviderDetailQueryVariables => ({
      healthCareProviderDetailId: param,
    }),
    mapFn: (res: HealthCareProviderDetailFragment) => res.companyName,
  },
  'trainings': {
    gql: TrainingTemplateDetailGQL,
    variablesFn: (param): TrainingTemplateDetailQueryVariables => ({
      trainingTemplateDetailId: param,
    }),
    mapFn: (res: TrainingTemplateDetailFragment) => res.templateName,
  },
  'tool-template': {
    gql: ToolTemplateDetailGQL,
    variablesFn: (param): ToolTemplateDetailQueryVariables => ({
      toolTemplateDetailId: param,
    }),
    mapFn: (res: ToolTemplateDetailFragment) => res.templateName,
  },
  'protective-gear': {
    gql: ProtectiveGearTemplateDetailGQL,
    variablesFn: (param): ProtectiveGearTemplateDetailQueryVariables => ({
      protectiveGearTemplateDetailId: param,
    }),
    mapFn: (res: ProtectiveGearTemplateDetailFragment) => res.templateName,
  },
  'workplace-inspections': {
    gql: WorkplaceInspectionTemplateDetailGQL,
    variablesFn: (param): WorkplaceInspectionTemplateDetailQueryVariables => ({
      workplaceInspectionTemplateDetailId: param,
    }),
    mapFn: (res: WorkplaceInspectionTemplateDetailFragment) => res.templateName,
  },
  'medical-examination': {
    gql: MedicalExaminationTemplateDetailGQL,
    variablesFn: (param): MedicalExaminationTemplateDetailQueryVariables => ({
      medicalExaminationTemplateDetailId: param,
    }),
    mapFn: (res: MedicalExaminationTemplateDetailFragment) => res.templateName,
  },
  'vehicles': {
    gql: VehicleTemplateDetailGQL,
    variablesFn: (param): VehicleTemplateDetailQueryVariables => ({
      vehicleTemplateDetailId: param,
    }),
    mapFn: (res: VehicleTemplateDetailFragment) => res.templateName,
  },
};
