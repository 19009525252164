import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { StepperTwoFactorEnum } from '@app/shared/constants/steppers/two-factor.stepper.constant';
import { PageSection } from '@app/shared/dto/page.dto';
import { stepperResolverFactory } from '@app/shared/factories/stepper.factory';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { BREADCRUMB_KEY } from '@ui';

const section: PageSection[] = ['two-factor'];

export const twoFactorRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./two-factor-root/two-factor-root.component'),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    children: [
      {
        path: '',
        title: titleResolverFactory({
          section: section,
          action: ['login'],
        }),
        loadComponent: () => import('./two-factor-login/two-factor-login.component'),
      },
      {
        path: ROUTE_DEFINITION.TWO_FACTOR.QR,
        title: titleResolverFactory({
          section: section,
          action: ['qr'],
        }),
        data: stepperResolverFactory<StepperTwoFactorEnum>({
          stepper: StepperTwoFactorEnum.QR,
        }),
        loadComponent: () => import('./two-factor-qr-code/two-factor-qr-code.component'),
      },
      {
        path: ROUTE_DEFINITION.TWO_FACTOR.CONFIRMATION,
        title: titleResolverFactory({
          section: section,
          action: ['confirmation'],
        }),
        data: stepperResolverFactory<StepperTwoFactorEnum>({
          stepper: StepperTwoFactorEnum.CONFIRMATION,
        }),
        loadComponent: () => import('./two-factor-confirmation/two-factor-confirmation.component'),
      },
      {
        path: ROUTE_DEFINITION.TWO_FACTOR.VERIFIED,
        title: titleResolverFactory({
          section: section,
          action: ['verified'],
        }),
        data: stepperResolverFactory<StepperTwoFactorEnum>({
          stepper: StepperTwoFactorEnum.VERIFIED,
        }),
        loadComponent: () => import('./two-factor-verified/two-factor-verified.component'),
      },
      {
        path: ROUTE_DEFINITION.TWO_FACTOR.SUCCESS,
        title: titleResolverFactory({
          section: section,
          action: ['verified'],
        }),
        data: stepperResolverFactory<StepperTwoFactorEnum>({
          stepper: StepperTwoFactorEnum.DONE,
        }),
        loadComponent: () => import('./two-factor-success/two-factor-success.component'),
      },
      {
        path: ROUTE_DEFINITION.TWO_FACTOR.SETTINGS,
        title: titleResolverFactory({
          section: section,
          action: ['login'],
        }),
        loadComponent: () => import('./two-factor-settings/two-factor-settings.component'),
      },
    ],
  },
];
