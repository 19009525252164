import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RootPermissionService } from '@app/shared/services/root-permission.service';
import { PermissionSectionChoices } from '@graphql/types';
import { map } from 'rxjs';

export const permissionCanReadGuardFactory = (section: PermissionSectionChoices): CanActivateFn => {
  return () => {
    const gql = inject(RootPermissionService);
    const router = inject(Router);
    return gql.canRead(section).pipe(map((canRead) => canRead || router.createUrlTree(['/'])));
  };
};

export const permissionCanUpdateGuardFactory = (section: PermissionSectionChoices): CanActivateFn => {
  return () => {
    const gql = inject(RootPermissionService);
    const router = inject(Router);
    return gql.canUpdate(section).pipe(map((canRead) => canRead || router.createUrlTree(['/'])));
  };
};
