import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { providePagination } from '@ui';

export const PAGINATION_PROVIDER = providePagination({
  i18n: {
    $translate: true,
    prev: _('ui.pagination.prev'),
    next: _('ui.pagination.next'),
  },
});
