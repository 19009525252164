/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PreSignedUploadFragment = { __typename?: 'PreSignedUpload'; id: any; url: string };

export type PreSignedUploadCreateMutationVariables = Types.Exact<{
  input: Array<Types.FileUploadInput> | Types.FileUploadInput;
}>;

export type PreSignedUploadCreateMutation = {
  __typename?: 'Mutation';
  result:
    | {
        __typename?: 'FileUploadInputErrorResponse';
        originalFileName: {
          __typename?: 'FileUploadInputOriginalFileNameErrorCodesFieldErrors';
          errors: Array<{
            __typename?: 'FileUploadInputOriginalFileNameErrorCodesFieldError';
            code: Types.FileUploadInputOriginalFileNameErrorCodes;
            message?: string | null;
            value?: string | null;
          }>;
        };
      }
    | {
        __typename?: 'PreSignedUploads';
        preSignedUploads: Array<{ __typename?: 'PreSignedUpload'; id: any; url: string }>;
      };
};

export const PreSignedUploadFragmentDoc = gql`
  fragment PreSignedUpload on PreSignedUpload {
    id
    url
  }
`;
export const PreSignedUploadCreateDocument = gql`
  mutation PreSignedUploadCreate($input: [FileUploadInput!]!) {
    result: preSignedUploadCreate(input: $input) {
      ... on PreSignedUploads {
        preSignedUploads {
          ...PreSignedUpload
        }
      }
      ... on FileUploadInputErrorResponse {
        originalFileName {
          errors {
            code
            message
            value
          }
        }
      }
    }
  }
  ${PreSignedUploadFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PreSignedUploadCreateGQL extends Apollo.Mutation<
  PreSignedUploadCreateMutation,
  PreSignedUploadCreateMutationVariables
> {
  override document = PreSignedUploadCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
