import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { provideAlert } from '@ui';

export const ALERT_PROVIDER = provideAlert({
  i18n: {
    $translate: true,
    info: _('ui.alert.info'),
    close: _('ui.alert.close'),
  },
});
