import { LanguageChoices } from '@graphql/types';
import { LanguageDto } from '../dto/language.dto';

export const DEFAULT_LANGUAGE: LanguageDto = 'cs';
export const AVAILABLE_LANGUAGES: LanguageDto[] = Object.values(LanguageChoices);

const UNDERSCORE: Record<LanguageDto, string> = {
  cs: 'cs_CZ',
  en: 'en_US',
  de: 'de_DE',
};

const DASH: Record<LanguageDto, string> = {
  cs: 'cs-CZ',
  en: 'en-US',
  de: 'de-DE',
};

export const LOCALES = {
  UNDERSCORE,
  DASH,
};

export const DAY_OF_WEEK_OFFSET: Record<LanguageDto, number> = {
  cs: 1,
  en: 1,
  de: 1,
};
