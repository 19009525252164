import { Routes } from '@angular/router';
import { BREADCRUMBS_GQL } from '@app/shared/constants/breadcrumb-gql.constants';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { PageSection } from '@app/shared/dto/page.dto';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { BREADCRUMB_KEY } from '@ui';

const section: PageSection[] = ['settings', 'data', 'positions'];

const DEFAULT_JOB_BREADCRUMB_OPTIONS = {
  [BREADCRUMB_KEY]: breadcrumbFactory({
    title: 'id',
    gqlOptions: BREADCRUMBS_GQL['job-positions'],
  }),
};

export const positionsRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section: section,
      action: ['list'],
    }),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    loadComponent: () => import('./settings-data-positions-list/settings-data-positions-list.component'),
  },
  {
    path: `:id`,
    title: titleResolverFactory({
      section: section,
      action: ['detail'],
    }),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    loadComponent: () => import('./settings-data-positions-detail/settings-data-positions-detail.component'),
    children: [
      {
        path: '',
        title: titleResolverFactory({
          section: section,
          action: ['professions'],
        }),
        data: {
          ...DEFAULT_JOB_BREADCRUMB_OPTIONS,
        },
        loadComponent: () =>
          import('./settings-data-positions-professions/settings-data-positions-professions.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.CERTIFICATION,
        title: titleResolverFactory({
          section: section,
          action: ['certification'],
        }),
        data: {
          ...DEFAULT_JOB_BREADCRUMB_OPTIONS,
        },
        loadComponent: () =>
          import('./settings-data-positions-certification-list/settings-data-positions-certification-list.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.MEDICAL_EXAMINATION_TYPE,
        title: titleResolverFactory({
          section: section,
          action: ['medical-examination-type'],
        }),
        data: {
          ...DEFAULT_JOB_BREADCRUMB_OPTIONS,
        },
        loadComponent: () =>
          import(
            './settings-data-positions-medical-examination-type-list/settings-data-positions-medical-examination-type-list.component'
          ),
      },
      {
        path: ROUTE_DEFINITION.ROOT.PROTECTIVE_GEAR,
        title: titleResolverFactory({
          section: section,
          action: ['protective-gear'],
        }),
        data: {
          ...DEFAULT_JOB_BREADCRUMB_OPTIONS,
        },
        loadComponent: () =>
          import(
            './settings-data-positions-protective-gear-list/settings-data-positions-protective-gear-list.component'
          ),
      },
      {
        path: ROUTE_DEFINITION.ROOT.RISKS,
        title: titleResolverFactory({
          section: section,
          action: ['risks'],
          title: 'id',
        }),
        data: {
          ...DEFAULT_JOB_BREADCRUMB_OPTIONS,
        },
        loadComponent: () => import('./settings-data-positions-risks/settings-data-positions-risks.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.HAZARDS,
        title: titleResolverFactory({
          section: section,
          action: ['hazards'],
          title: 'id',
        }),
        data: {
          ...DEFAULT_JOB_BREADCRUMB_OPTIONS,
        },
        loadComponent: () => import('./settings-data-positions-hazards/settings-data-positions-hazards.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.RISK_FACTORS,
        title: titleResolverFactory({
          section: section,
          action: ['risk-factors'],
          title: 'id',
        }),
        data: {
          ...DEFAULT_JOB_BREADCRUMB_OPTIONS,
        },
        loadComponent: () =>
          import('./settings-data-positions-risk-factors/settings-data-positions-risk-factors.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.MEDICAL_EXAMINATIONS,
        title: titleResolverFactory({
          section: section,
          action: ['medical-examinations'],
          title: 'id',
        }),
        data: {
          ...DEFAULT_JOB_BREADCRUMB_OPTIONS,
        },
        loadComponent: () =>
          import(
            './settings-data-positions-medical-examinations/settings-data-positions-medical-examinations.component'
          ),
      },
      {
        path: ROUTE_DEFINITION.ROOT.THREATS,
        title: titleResolverFactory({
          section: section,
          action: ['threats'],
        }),
        data: {
          ...DEFAULT_JOB_BREADCRUMB_OPTIONS,
        },
        loadComponent: () => import('./settings-data-positions-threats/settings-data-positions-threats.component'),
      },
    ],
  },
];
