import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { provideToast } from '@ui';

export const TOAST_PROVIDER = provideToast({
  i18n: {
    $translate: true,
    close: _('uni.close'),
    icon: {
      danger: _('uni.danger'),
      success: _('uni.success'),
      warning: _('uni.warning'),
    },
  },
});
