import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { twoFactorRoutes } from '@app/two-factor/routes';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { BREADCRUMB_KEY } from '@ui';

const section: PageSection[] = ['settings', 'user'];

export const settingsUserSecurityRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section: section,
      action: ['basic'],
    }),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    loadComponent: () => import('./settings-user-security-root/settings-user-security.component'),
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_USER.TWO_FACTOR,
    title: titleResolverFactory({
      section: section,
      action: ['login'],
    }),
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'two-factor' }),
    },
    children: twoFactorRoutes,
  },
];
