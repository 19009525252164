import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { PageSection } from '../shared/dto/page.dto';
import { titleResolverFactory } from '../shared/factories/title.factory';

const section: PageSection[] = ['employees', 'employees'];

export const billingRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section,
      action: ['list'],
    }),
    loadComponent: () => import('./billing-root/billing-root.component'),
    children: [
      {
        path: '',
        loadComponent: () => import('./billing-info/billing-info.component'),
      },
      {
        path: ROUTE_DEFINITION.BILLING.TRANSACTIONS,
        loadComponent: () => import('./billing-transactions/billing-transactions.component'),
      },
    ],
  },
];
