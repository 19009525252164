import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { environment } from '@env/environment';
import { MeQuery } from '@graphql/query/root/generated/me.apollo';
import { TenantStatusChoices } from '@graphql/types';
import { NgxPendoService } from 'ngx-pendo';
import { OperatorFunction, map } from 'rxjs';
import { ROUTE_DEFINITION } from '../constants/route-definition.constant';

declare const pendo: any;

export const meGuard = (
  router: Router,
  type: 'redirect' | 'isLogged' | 'notLogged',
): OperatorFunction<ApolloQueryResult<MeQuery>, boolean | UrlTree> => {
  const pendoService = inject(NgxPendoService);
  return (input$) => {
    return input$.pipe(
      map((res) => res.data.me),
      map((me) => {
        const loggedUrl = router.createUrlTree(['/', ROUTE_DEFINITION.APP.DASHBOARD]);
        const notLoggedUrl = router.createUrlTree(['/', ROUTE_DEFINITION.APP.LOGIN]);
        const missingCompanyUrl = router.createUrlTree(['/', ROUTE_DEFINITION.APP.REGISTER_COMPANY]);
        const bootstrappingUrl = router.createUrlTree(['/', ROUTE_DEFINITION.APP.BOOTSTRAPPING]);
        const paymentUrl = router.createUrlTree(['/', ROUTE_DEFINITION.APP.PAYMENT]);

        const isActiveTenant = me?.activeTenant;
        const isPaid = isActiveTenant?.billingSubscription;

        // initialize tracking on production and testing
        if (me?.authUser && typeof pendo !== 'undefined' && environment.name !== 'localhost') {
          pendoService.initialize(
            {
              id: me.authUser.id,
              name: `${me.authUser.firstName} ${me.authUser.lastName}`,
              email: me.authUser.email,
            },
            me.activeTenant
              ? {
                  id: me.authUser.id,
                  name: me.activeTenant.companyName,
                  payingStatus: me.activeTenant.billingSubscription?.isActive ? 'true' : 'false',
                }
              : undefined,
          );
        }

        switch (type) {
          case 'isLogged':
            // User does not exist, redirect to login
            if (!me) return notLoggedUrl;

            // Id tenant is not active, user has to register company
            if (!isActiveTenant) return missingCompanyUrl;

            // If tenant is already active and has not billing subscription, user has to pay
            if (!isPaid || !isPaid.isActive) return paymentUrl;

            switch (isActiveTenant?.status) {
              case TenantStatusChoices.Active:
                return true;
              case TenantStatusChoices.Bootstrapping:
              case TenantStatusChoices.BootstrapFailed:
              case TenantStatusChoices.Uninitialized:
                return bootstrappingUrl;
              default:
                return notLoggedUrl;
            }
          case 'notLogged':
            return !isActiveTenant || loggedUrl;
          default:
            return isActiveTenant ? loggedUrl : notLoggedUrl;
        }
      }),
    );
  };
};
