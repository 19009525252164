import { Routes } from '@angular/router';
import { settingsAccountRoutes } from '@app/settings-account/routes';
import { settingsDataRoutes } from '@app/settings-data/routes';
import { settingsTemplatesRoutes } from '@app/settings-templates/routes';
import { settingsUserRoutes } from '@app/settings-user/routes';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { BREADCRUMB_KEY } from '@ui';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';

export const settingsRootRoutes: Routes = [
  {
    pathMatch: 'full',
    path: '',
    redirectTo: ROUTE_DEFINITION.SETTINGS.ACCOUNT,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS.USER,
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'user-settings' }),
    },
    children: settingsUserRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS.ACCOUNT,
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'account' }),
    },
    children: settingsAccountRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS.DATA,
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'data' }),
    },
    children: settingsDataRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS.TEMPLATES,
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'templates' }),
    },
    children: settingsTemplatesRoutes,
  },
];
