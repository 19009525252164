import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { BREADCRUMB_KEY } from '@ui';

const section: PageSection[] = ['settings', 'user', 'notifications'];

export const settingsUserNotificationRoutes: Routes = [
  {
    path: ROUTE_DEFINITION.SETTINGS_USER.NOTIFICATIONS,
    loadComponent: () => import('./settings-user-notifications-root/settings-user-notifications-root.component'),
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'user-notifications' }),
    },
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: ROUTE_DEFINITION.ROOT.EMAIL,
      },
      {
        path: ROUTE_DEFINITION.ROOT.EMAIL,
        title: titleResolverFactory({
          section: section,
          action: ['email'],
        }),
        data: {
          [BREADCRUMB_KEY]: null,
        },
        loadComponent: () => import('./settings-user-notifications-email/settings-user-notifications-email.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.DESKTOP,
        title: titleResolverFactory({
          section: section,
          action: ['desktop'],
        }),
        data: {
          [BREADCRUMB_KEY]: null,
        },
        loadComponent: () =>
          import('./settings-user-notifications-desktop/settings-user-notifications-desktop.component'),
      },
    ],
  },
];
