import { inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { DynamicFormsErrorMessages, provideDynamicForms } from '@ui';
import { map } from 'rxjs';
import { BE_ERRORS } from '../i18n/errors.i18n';

export const DYNAMIC_FORMS_PROVIDER = provideDynamicForms({
  i18n: {
    $translate: true,
    emptyCustomComponent: _('ui.dynamic-forms.empty-custom-component'),
    emptyFormContent: _('ui.dynamic-forms.empty-form-content'),
  },
  errorMessagesFactory: () => {
    const transloco = inject(TranslocoService);

    return transloco.selectTranslation().pipe(
      map(() => {
        const buitInErrors: DynamicFormsErrorMessages = {
          required: () => transloco.translate(_('validation.fe.required')),
          requiredTrue: () => transloco.translate(_('validation.fe.required-true')),
          email: () => transloco.translate(_('validation.fe.email')),
          pattern: () => transloco.translate(_('validation.fe.pattern')),
          minLength: (res: { requiredLength: number }) =>
            transloco.translate(_('validation.fe.min-length'), { requiredLength: res.requiredLength }),
          maxLength: (res: { requiredLength: number }) =>
            transloco.translate(_('validation.fe.max-length'), { requiredLength: res.requiredLength }),
          max: (res: { max: number }) => transloco.translate(_('validation.fe.max'), { max: res.max }),
          min: (res: { min: number }) => transloco.translate(_('validation.fe.min'), { min: res.min }),
          nullValidator: () => '',
          // custom validators
          passwordShouldMatch: () => transloco.translate(_('validation.fe.password-should-match')),
          dateRange: () => transloco.translate(_('validation.fe.date-range')),
          modulo: (res: { divisor: number }) => {
            return transloco.translate(_('validation.fe.modulo'), { divisor: res.divisor });
          },
          isValid: () => transloco.translate(_('validation.fe.is-valid')),
          date: () => transloco.translate(_('validation.fe.date')),
        };

        const beErrorsTransformed: { [key: string]: () => string } = {};

        for (const [key, value] of Object.entries(BE_ERRORS)) {
          beErrorsTransformed[key] = (): string => {
            return transloco.translate(value);
          };
        }

        return {
          ...beErrorsTransformed,

          // built-in errors
          ...buitInErrors,
        };
      }),
    );
  },
});
