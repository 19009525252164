import { Routes } from '@angular/router';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { BREADCRUMB_KEY } from '@ui';

const section: PageSection[] = ['settings', 'account'];

export const settingsAccountSecurityRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section: section,
      action: ['security'],
    }),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    loadComponent: () => import('./settings-account-security/settings-account-security.component'),
  },
];
