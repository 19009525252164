import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { PageAction, PageSection } from '../dto/page.dto';

export const PAGE_ACTION_I18N: Record<PageAction, string> = {
  list: _('titles.action.list'),
  create: _('titles.action.create'),
  activity: _('titles.action.activity'),
  detail: _('titles.action.detail'),
  edit: _('titles.action.edit'),
  permissions: _('titles.action.permissions'),
  access: _('titles.action.access'),
  archive: _('titles.action.archive'),
  basic: _('titles.action.basic'),
  security: _('titles.action.security'),
  email: _('titles.action.email'),
  desktop: _('titles.action.desktop'),
  opp: _('titles.action.opp'),
  professions: _('titles.action.professions'),
  risks: _('titles.action.risks'),
  'risk-factors': _('titles.action.risk-factors'),
  'medical-examinations': _('titles.action.medical-examinations'),
  hazards: _('titles.action.hazards'),
  threats: _('titles.action.threats'),
  qr: _('titles.action.qr'),
  confirmation: _('titles.action.confirmation'),
  verified: _('titles.action.verified'),
  finished: _('titles.action.finished'),
  login: _('titles.action.login'),
  'protective-gear': _('titles.action.protective-gear'),
  certification: _('titles.action.certification'),
  'medical-examination-type': _('titles.action.medical-examination-type'),
};

export const PAGE_SECTION_I18N: Record<PageSection, string> = {
  account: _('titles.section.account'),
  login: _('titles.section.login'),
  'reset-password': _('titles.section.reset-password'),
  users: _('titles.section.users'),
  'not-found': _('titles.section.not-found'),
  register: _('titles.section.register'),
  'register-company': _('titles.section.register-company'),
  activation: _('titles.section.activation'),
  bootstrapping: _('titles.section.bootstrapping'),
  payment: _('titles.section.payment'),
  join: _('titles.section.join'),
  dashboard: _('titles.section.dashboard'),
  employees: _('titles.section.employees'),
  injuries: _('titles.section.injuries'),
  examinations: _('titles.section.examinations'),
  workplaces: _('titles.section.workplaces'),
  inspections: _('titles.section.inspections'),
  'corrective-actions': _('titles.section.corrective-actions'),
  settings: _('titles.section.settings'),
  roles: _('titles.section.roles'),
  medical: _('titles.section.medical'),
  opinions: _('titles.section.opinions'),
  equipment: _('titles.section.equipment'),
  vehicles: _('titles.section.vehicles'),
  tools: _('titles.section.tools'),
  revisions: _('titles.section.revisions'),
  'protective-gear': _('titles.section.protective-gear'),
  certificates: _('titles.section.certificates'),
  education: _('titles.section.education'),
  trainings: _('titles.section.trainings'),
  templates: _('titles.section.templates'),
  user: _('titles.section.user'),
  notifications: _('titles.section.notifications'),
  integrations: _('titles.section.integrations'),
  positions: _('titles.section.positions'),
  data: _('titles.section.data'),
  'custom-props': _('titles.section.custom-props'),
  'forgotten-password': _('titles.section.forgotten-password'),
  'two-factor': _('titles.section.two-factor'),
  'backup-code': _('titles.section.backup-code'),
  translator: _('uni.translator'),
  'tool-type': _('titles.section.tool-type'),
  certification: _('titles.section.certification'),
  'medical-examination-type': _('titles.section.medical-examination-type'),
  'health-care-provider': _('titles.section.health-care-provider'),
  'vehicle-control': _('titles.section.vehicle-control'),
  training: _('titles.section.training'),
  tool: _('titles.section.tool'),
  'medical-examination': _('titles.section.medical-examination'),
  'workplace-inspections': _('titles.section.workplace-inspections'),
  vehicle: _('titles.section.vehicle'),
  admin: _('titles.section.admin'),
  companies: _('titles.section.companies'),
};
