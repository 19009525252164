import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { permissionCanUpdateGuardFactory } from '@app/shared/guards/permission.guard';
import { PermissionSectionChoices } from '@graphql/types';
import { BREADCRUMB_KEY } from '@ui';
import { certificationRoutes } from './certification/routes';
import { customPropsRoutes } from './custom-props/routes';
import { healthCareProviderRoutes } from './health-care-provider/routes';
import { medicalTypeRoutes } from './medical-examination-type/routes';
import { positionsRoutes } from './positions/routes';
import { toolTypeRoutes } from './tool-type/routes';

export const settingsDataRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTE_DEFINITION.SETTINGS_DATA.CUSTOM_PROPS,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_DATA.CUSTOM_PROPS,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.AccountDataTypeDefinitions)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'custom-props' }),
    },
    children: customPropsRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_DATA.POSITIONS,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.AccountDataTypeDefinitions)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'job-positions' }),
    },
    children: positionsRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_DATA.TOOL_TYPE,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.AccountDataTypeDefinitions)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'tool-types' }),
    },
    children: toolTypeRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_DATA.CERTIFICATION,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.AccountDataTypeDefinitions)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'certifications' }),
    },
    children: certificationRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_DATA.MEDICAL_EXAMINATION_TYPE,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.AccountDataTypeDefinitions)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'medical-examination-types' }),
    },
    children: medicalTypeRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_DATA.HEALTH_CARE_PROVIDER,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.AccountDataTypeDefinitions)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'health-care-providers' }),
    },
    children: healthCareProviderRoutes,
  },
];
