import { Routes } from '@angular/router';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { BREADCRUMB_KEY } from '@ui';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { BREADCRUMBS_GQL } from '@app/shared/constants/breadcrumb-gql.constants';

const section: PageSection[] = ['settings', 'templates', 'training'];

export const trainingTemplateRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section,
      action: ['list'],
    }),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    loadComponent: () => import('./training-template-list/training-template-list.component'),
  },
  {
    path: ':id',
    title: titleResolverFactory({
      section,
      action: ['detail'],
      title: 'id',
    }),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    children: [
      {
        path: '',
        data: {
          [BREADCRUMB_KEY]: breadcrumbFactory({
            title: 'id',
            gqlOptions: BREADCRUMBS_GQL.trainings,
          }),
        },
        loadComponent: () => import('./training-template-detail/training-template-detail.component'),
      },
    ],
  },
];
