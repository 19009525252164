import { ResolveFn } from '@angular/router';
import { PageAction, PageSection } from '../dto/page.dto';
import { PAGE_ACTION_I18N, PAGE_SECTION_I18N } from '../i18n/titles.i18n';

interface TitleResolverOptions {
  section: PageSection[];
  action?: PageAction[];
  title?: 'id' | 'object-type';
}

export const titleResolverFactory = (options: TitleResolverOptions): ResolveFn<string> => {
  return (route) => {
    let title = undefined;
    if (options.title) {
      title = route.paramMap.get(options.title) || route.parent?.paramMap.get(options.title) || undefined;
    }
    const data = {
      section: options.section.map((i) => PAGE_SECTION_I18N[i]),
      actions: options.action?.map((i) => PAGE_ACTION_I18N[i]),
      title,
    };
    return JSON.stringify(data);
  };
};
