import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { ModalPopupComponent } from '@ui';
import { first } from 'rxjs';

export const updateAppFactory = (): (() => void) => {
  const transloco = inject(TranslocoService);
  const dialog = inject(Dialog);
  const overlay = inject(Overlay);
  return (): void => {
    transloco
      .selectTranslation()
      .pipe(first())
      .subscribe(() => {
        const title = transloco.translate(_('modal.update.title'));
        const content = transloco.translate(_('modal.update.content'));
        const closeBtnText = transloco.translate(_('uni.close'));
        const confirmBtnText = transloco.translate(_('modal.update.confirm-btn-text'));

        const dialogRef = dialog.open<boolean>(ModalPopupComponent, {
          data: { title, content, closeBtnText, confirmBtnText },
          panelClass: 'modal-xs',
          positionStrategy: overlay.position().global().start('0.5rem').bottom('0.5rem'),
        });
        dialogRef.closed.subscribe((reload) => {
          if (reload) {
            window.location.reload();
          }
        });
      });
  };
};
