import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { BREADCRUMB_KEY } from '@ui';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { BREADCRUMBS_GQL } from '@app/shared/constants/breadcrumb-gql.constants';

const section: PageSection[] = ['settings', 'account', 'users'];

export const settingsAccountUsersRoutes: Routes = [
  {
    path: '',
    data: {
      [BREADCRUMB_KEY]: null,
    },
    loadComponent: () => import('./settings-account-users-root/settings-account-users-root.component'),
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: ROUTE_DEFINITION.ROOT.USERS,
      },
      {
        path: ROUTE_DEFINITION.ROOT.USERS,
        title: titleResolverFactory({
          section: section,
          action: ['list'],
        }),
        loadComponent: () => import('./settings-account-users-list/settings-account-users-list.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.ACCESS,
        title: titleResolverFactory({
          section: section,
          action: ['access'],
        }),
        loadComponent: () => import('./settings-account-users-access/settings-account-users-access.component'),
      },
    ],
  },
  {
    path: ':id',
    title: titleResolverFactory({
      section: section,
      action: ['detail'],
      title: 'id',
    }),
    loadComponent: () => import('./settings-account-users-detail/settings-account-users-detail.component'),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ROUTE_DEFINITION.ROOT.OVERVIEW,
      },
      {
        path: ROUTE_DEFINITION.ROOT.OVERVIEW,
        data: {
          [BREADCRUMB_KEY]: breadcrumbFactory({
            title: 'id',
            gqlOptions: BREADCRUMBS_GQL.user,
          }),
        },
        loadComponent: () =>
          import('./settings-account-user-detail-overview/settings-account-user-detail-overview.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.ACCESS,
        data: {
          [BREADCRUMB_KEY]: breadcrumbFactory({
            title: 'id',
            gqlOptions: BREADCRUMBS_GQL.user,
          }),
        },
        loadComponent: () =>
          import('./settings-account-user-detail-access/settings-account-user-detail-access.component'),
      },
    ],
  },
  {
    path: `${ROUTE_DEFINITION.SETTINGS_ACCOUNT.USERS}/:id/${ROUTE_DEFINITION.ROOT.ACCESS}`,
    title: titleResolverFactory({
      section: section,
      action: ['access'],
      title: 'id',
    }),
    loadComponent: () => import('./settings-account-users-access/settings-account-users-access.component'),
  },
  {
    path: `${ROUTE_DEFINITION.SETTINGS_ACCOUNT.USERS}/:id/${ROUTE_DEFINITION.ROOT.PERMISSIONS}`,
    title: titleResolverFactory({
      section: section,
      action: ['permissions'],
      title: 'id',
    }),
    loadComponent: () => import('./settings-account-users-permissions/settings-account-users-permissions.component'),
  },
];
