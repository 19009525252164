import { DOCUMENT } from '@angular/common';
import { DestroyRef, Directive, LOCALE_ID, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Meta } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { AVAILABLE_LANGUAGES, LOCALES } from '../constants/language.constant';
import { LanguageDto } from '../dto/language.dto';

@Directive({
  selector: '[appHtmlLang]',
  standalone: true,
})
export class HtmlLangDirective implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  private readonly transloco = inject(TranslocoService);
  private readonly translocoLocale = inject(TranslocoLocaleService);
  private readonly meta = inject(Meta);
  private readonly document = inject(DOCUMENT);
  public locale = inject(LOCALE_ID);

  public ngOnInit(): void {
    this.transloco.langChanges$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((lang) => {
      const language = lang as LanguageDto;
      this.setOgTags(language);
      this.setHtmlLang(language);
      this.setLocale(language);
    });
  }

  private setOgTags(language: LanguageDto): void {
    this.meta.removeTag(`content='${LOCALES.UNDERSCORE[language]}'`);
    this.meta.updateTag({ property: 'og:locale', content: LOCALES.UNDERSCORE[language] });
    this.meta.addTags(
      AVAILABLE_LANGUAGES.filter((lang) => lang !== language).map((lang) => ({
        property: 'og:locale:alternate',
        content: LOCALES.UNDERSCORE[lang],
      })),
    );
  }

  private setHtmlLang(language: LanguageDto): void {
    if (this.document?.documentElement) {
      this.document.documentElement.lang = language;
    }
  }

  private setLocale(language: LanguageDto): void {
    this.locale = LOCALES.DASH[language];
    this.translocoLocale.setLocale(LOCALES.DASH[language]);
  }
}
