import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { BreadcrumbResolverOptions } from '@app/shared/factories/breadcrumb.factory';
import { faCog, faDatabase, faNewspaper, faUser } from '@fortawesome/free-solid-svg-icons';

export type SettingsSection =
  | 'user-settings'
  | 'detail'
  | 'user'
  | 'security'
  | 'user-notifications'
  | 'account-notifications'
  | 'two-factor'
  | 'account'
  | 'account-detail'
  | 'users'
  | 'roles'
  | 'data'
  | 'integrations'
  | 'custom-props'
  | 'tool-types'
  | 'certifications'
  | 'job-positions'
  | 'medical-examination-types'
  | 'health-care-providers'
  | 'templates'
  | 'trainings'
  | 'protective-gear'
  | 'medical-examination'
  | 'tool-template'
  | 'workplace-inspections'
  | 'vehicles';

export const SETTINGS_SECTIONS: Record<SettingsSection, BreadcrumbResolverOptions> = {
  'user-settings': {
    title: _('uni.settings'),
    icon: faUser,
  },
  'detail': {
    title: _('uni.detail'),
  },
  'user': {
    title: _('uni.user-preferences'),
  },
  'security': {
    title: _('uni.security'),
  },
  'user-notifications': {
    title: _('uni.user-notifications'),
  },
  'account-notifications': {
    title: _('uni.account-notifications'),
  },
  'two-factor': {
    title: _('login-enhanced.title'),
  },
  'account': {
    title: _('uni.account-preferences'),
    icon: faCog,
  },
  'account-detail': {
    title: _('settings.account.basic.title'),
  },
  'users': {
    title: _('settings.users.title'),
  },
  'roles': {
    title: _('settings.roles.title'),
  },
  'data': {
    title: _('uni.data-management'),
    icon: faDatabase,
  },
  'integrations': {
    title: _('uni.integrations'),
  },
  'custom-props': {
    title: _('uni.custom-props'),
  },
  'job-positions': {
    title: _('uni.position'),
  },
  'tool-types': {
    title: _('uni.equipment-tool-type'),
  },
  'certifications': {
    title: _('uni.certifications'),
  },
  'medical-examination-types': {
    title: _('uni.medical-examination-types'),
  },
  'health-care-providers': {
    title: _('uni.health-care-providers'),
  },
  'templates': {
    title: _('uni.templates'),
    icon: faNewspaper,
  },
  'trainings': {
    title: _('uni.training-templates'),
  },
  'tool-template': {
    title: _('uni.tool-templates'),
  },
  'protective-gear': {
    title: _('uni.protective-gear-templates'),
  },
  'medical-examination': {
    title: _('uni.medical-examination-templates'),
  },
  'workplace-inspections': {
    title: _('uni.workplace-inspections-templates'),
  },
  'vehicles': {
    title: _('uni.vehicle-templates'),
  },
};
