import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { BREADCRUMB_KEY } from '@ui';

const section: PageSection[] = ['settings', 'account', 'notifications'];

export const settingsAccountNotificationRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./settings-account-notification-root/settings-account-notification-root.component'),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: ROUTE_DEFINITION.ROOT.EMAIL,
      },
      {
        path: ROUTE_DEFINITION.ROOT.EMAIL,
        title: titleResolverFactory({
          section: section,
          action: ['email'],
        }),
        loadComponent: () =>
          import('./settings-account-notification-email/settings-account-notification-email.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.DESKTOP,
        title: titleResolverFactory({
          section: section,
          action: ['desktop'],
        }),
        loadComponent: () =>
          import('./settings-account-notification-desktop/settings-account-notification-desktop.component'),
      },
    ],
  },
];
