import { Routes } from '@angular/router';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { permissionCanReadGuardFactory, permissionCanUpdateGuardFactory } from '@app/shared/guards/permission.guard';
import { PermissionSectionChoices } from '@graphql/types';
import { BREADCRUMB_KEY } from '@ui';
import { ROUTE_DEFINITION } from '../shared/constants/route-definition.constant';
import { settingsAccountBasicRoutes } from './basic/routes';
import { settingsAccountIntegrationsRoutes } from './integrations/routes';
import { settingsAccountNotificationRoutes } from './notification/routes';
import { settingsAccountSecurityRoutes } from './security/routes';
import { settingsAccountUserRolesRoutes } from './user-roles/routes';
import { settingsAccountUsersRoutes } from './users/routes';

export const settingsAccountRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTE_DEFINITION.SETTINGS_ACCOUNT.BASIC,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_ACCOUNT.BASIC,
    canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.AccountSettings)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'account-detail' }),
    },
    children: settingsAccountBasicRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_ACCOUNT.SECURITY,
    canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.AccountSettings)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'security' }),
    },
    children: settingsAccountSecurityRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_ACCOUNT.USERS,
    canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.AccountSettingsUsersAndRoles)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'users' }),
    },
    children: settingsAccountUsersRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_ACCOUNT.ROLES,
    canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.AccountSettingsUsersAndRoles)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'roles' }),
    },
    children: settingsAccountUserRolesRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_ACCOUNT.INTEGRATIONS,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.AccountDataTypeDefinitions)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'integrations' }),
    },
    children: settingsAccountIntegrationsRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_ACCOUNT.NOTIFICATIONS,
    canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.AccountSettingsNotifications)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'account-notifications' }),
    },
    children: settingsAccountNotificationRoutes,
  },
];
