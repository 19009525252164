import { ALERT_PROVIDER } from './alert.provider';
import { BADGE_PROVIDER } from './badge.provider';
import { BUTTON_GROUP_PROVIDER } from './button-group.provider';
import { BUTTON_PROVIDER } from './button.provider';
import { CHIPS_PROVIDER } from './chips.provider';
import { DRAWER_PROVIDER } from './drawer.provider';
import { DROPDOWN_PROVIDER } from './dropdown.provider';
import { DYNAMIC_FORMS_PROVIDER } from './dynamic-forms.provider';
import { EMPTY_PROVIDER } from './empty.provider';
import { FILTERS_PROVIDER } from './filters.provider';
import { INPUT_FILE_UPLOADER_PROVIDER, INPUT_PROVIDER } from './input.provider';
import { MODAL_PROVIDER } from './modal.provider';
import { PAGINATION_INFO_PROVIDER } from './pagination-info.provider';
import { PAGINATION_PROVIDER } from './pagination.provider';
import { SORT_PROVIDER } from './sort.provider';
import { SPINNER_BLOCK_PROVIDER } from './spinner-block.provider';
import { SPINNER_PROVIDER } from './spinner.provider';
import { TABLE_EDIT_PROVIDER } from './table-edit.provider';
import { TABLE_PROVIDER } from './table.provider';
import { TABS_PROVIDER } from './tabs.provider';
import { TITLE_STRATEGY_PROVIDER } from './title-strategy.provider';
import { TOAST_PROVIDER } from './toast.provider';
import { TOOLTIP_PROVIDER } from './tooltip.provider';

export const UI_PROVIDERS = [
  TITLE_STRATEGY_PROVIDER,
  INPUT_FILE_UPLOADER_PROVIDER,
  ALERT_PROVIDER,
  BADGE_PROVIDER,
  BUTTON_GROUP_PROVIDER,
  BUTTON_PROVIDER,
  CHIPS_PROVIDER,
  DRAWER_PROVIDER,
  DROPDOWN_PROVIDER,
  DYNAMIC_FORMS_PROVIDER,
  EMPTY_PROVIDER,
  FILTERS_PROVIDER,
  INPUT_PROVIDER,
  MODAL_PROVIDER,
  PAGINATION_INFO_PROVIDER,
  PAGINATION_PROVIDER,
  SORT_PROVIDER,
  SPINNER_BLOCK_PROVIDER,
  SPINNER_PROVIDER,
  TABLE_EDIT_PROVIDER,
  TABLE_PROVIDER,
  TABS_PROVIDER,
  TOAST_PROVIDER,
  TOOLTIP_PROVIDER,
];
