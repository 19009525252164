import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { provideTableEdit } from '@ui';

export const TABLE_EDIT_PROVIDER = provideTableEdit({
  i18n: {
    $translate: true,
    closeBtnText: _('uni.close'),
    confirmBtnText: _('uni.confirm'),
    title: _('ui.table-edit.title'),
    search: {
      label: _('ui.table-edit.search.label'),
      placeholder: _('ui.table-edit.search.placeholder'),
    },
    itemsNotFound: _('ui.table-edit.items-not-found'),
  },
});
