import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SETTINGS_SECTIONS, SettingsSection } from '@app/shared/constants/settings.constants';
import { ResolveFn } from '@angular/router';
import { BreadcrumbsGqlVariables } from '@ui';

export interface BreadcrumbsGqlOptions {
  // todo types
  gql: any;
  variablesFn: (param: string) => BreadcrumbsGqlVariables;
  mapFn: (res: any) => string;
}

export interface BreadcrumbResolverOptions {
  title: string | undefined;
  icon?: IconDefinition;
  gqlOptions?: BreadcrumbsGqlOptions;
}

interface BreadcrumbFactoryOptions {
  title: 'id' | SettingsSection;
  gqlOptions?: BreadcrumbsGqlOptions;
}

export const breadcrumbFactory = (options: BreadcrumbFactoryOptions): ResolveFn<BreadcrumbResolverOptions | null> => {
  return (route) => {
    if (options.title === 'id') {
      const title = route.paramMap?.get(options.title) || route.parent?.paramMap?.get(options.title) || '';
      return {
        title,
        gqlOptions: options.gqlOptions, // for dynamic title fetching
      };
    }
    return {
      title: SETTINGS_SECTIONS[options.title].title,
      icon: SETTINGS_SECTIONS[options.title]?.icon,
    };
  };
};
