import { Routes } from '@angular/router';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';

const section: PageSection[] = ['admin', 'companies'];

export const adminRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section: section,
      action: ['list'],
    }),
    // canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.Admin)], // TODO there is no permission for this section
    loadComponent: () => import('./tenant-list/tenant-list.component'),
  },
];
