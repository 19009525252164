import { Route, Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '../shared/constants/route-definition.constant';
import { titleResolverFactory } from '../shared/factories/title.factory';

const createRootRoute = (path: string, route: Route): Route => {
  return {
    path,
    // canActivate: [notLoggedGuard], // TODO blocking or not blocking(?)
    loadComponent: () => import('../layout/layout-system/layout-system.component'),
    children: [route],
  };
};

export const rootRoutesSystem: Routes = [
  createRootRoute(ROUTE_DEFINITION.APP.BOOTSTRAPPING, {
    path: '',
    title: titleResolverFactory({
      section: ['bootstrapping'],
    }),
    loadComponent: () => import('../bootstrapping/bootstrapping.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.PAYMENT, {
    path: '',
    title: titleResolverFactory({
      section: ['payment'],
    }),
    loadComponent: () => import('../payment/payment.component'),
  }),
  createRootRoute(ROUTE_DEFINITION.APP.PAYMENT_SUCCESS, {
    path: '',
    title: titleResolverFactory({
      section: ['payment'],
    }),
    loadComponent: () => import('../payment-success/payment-success.component'),
  }),
];
