import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { BREADCRUMB_KEY } from '@ui';

const section: PageSection[] = ['settings', 'account'];

export const settingsAccountBasicRoutes: Routes = [
  {
    path: '',
    data: {
      [BREADCRUMB_KEY]: null,
    },
    loadComponent: () => import('./settings-account-basic-root/settings-account-basic-root.component'),
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: ROUTE_DEFINITION.ROOT.BASIC,
      },
      {
        path: ROUTE_DEFINITION.ROOT.BASIC,
        title: titleResolverFactory({
          section: section,
          action: ['basic'],
        }),
        loadComponent: () => import('./settings-account-basic/settings-account-basic.component'),
      },
      {
        path: ROUTE_DEFINITION.ROOT.ACTIVITY,
        title: titleResolverFactory({
          section: section,
          action: ['activity'],
        }),
        loadComponent: () => import('./settings-account-basic-activity/settings-account-basic-activity.component'),
      },
    ],
  },
];
