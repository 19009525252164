import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  private pathI18n(lang: string): string {
    if (lang.length) {
      const env = this.convertEnv(environment.name);

      return environment.translations.remote
        ? `${environment.translations.uri}${env}/front/${lang}/translations.json`
        : `assets/i18n/${lang}.json`;
    } else {
      // This is for ngx-reveal-translations
      return `assets/i18n/empty.json`;
    }
  }

  private convertEnv(env: string): string {
    switch (env) {
      case 'localhost':
      case 'develop':
        return 'development';
      case 'testing':
        return 'testing';
      case 'prod':
        return 'production';
      default:
        return env;
    }
  }

  public getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(this.pathI18n(lang), {
      headers: { 'Cache-Control': 'no-cache' },
    });
  }
}
