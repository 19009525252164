import { DestroyRef, inject, Injectable } from '@angular/core';

import { GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@env/environment';
import {
  TenantDetailByAuthSubdomainFragment,
  TenantDetailByAuthSubdomainGQL,
} from '@graphql/query/object/generated/tenant-detail-by-auth-subdomain.apollo';
import { catchError, map, Observable, of } from 'rxjs';
import { UrlUtils } from '../utils/url.utils';
import { ToastHelperService } from './toast-helper.service';

@Injectable({ providedIn: 'root' })
export class TenantDomainService {
  private tenantInfo = inject(TenantDetailByAuthSubdomainGQL);
  private readonly destroyRef = inject(DestroyRef);
  private toastHelper = inject(ToastHelperService);

  private defaultSocial = {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.social.google.clientId, {
          oneTapEnabled: false,
        }),
      },
      {
        id: MicrosoftLoginProvider.PROVIDER_ID,
        provider: new MicrosoftLoginProvider(environment.social.microsoft.clientId, {
          authority: environment.social.microsoft.authorityUrl + 'common', // Common is used for personal accounts
          redirect_uri: environment.rootUrl,
          logout_redirect_uri: environment.social.microsoft.logoutUrl,
          scopes: ['https://graph.microsoft.com/.default'],
        }),
      },
    ],
    onError: (err) => {
      throw new Error(err);
    },
  } as SocialAuthServiceConfig;

  public getSocialProviders(): Observable<SocialAuthServiceConfig> {
    const authSubdomain = UrlUtils.getSubdomain();

    if (!authSubdomain) return of(this.defaultSocial);

    return this.tenantInfo.fetch({ authSubdomain }).pipe(
      map((res) => res?.data?.object as TenantDetailByAuthSubdomainFragment | undefined),
      map((res) => this.mapTenantInfo(res)),
      catchError((err) => {
        this.toastHelper.handleError(err);
        return of(this.defaultSocial);
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private mapTenantInfo(res: TenantDetailByAuthSubdomainFragment | undefined): SocialAuthServiceConfig {
    const providers = [];

    if (res?.authMicrosoft.isEnabled && res?.authMicrosoft.clientId) {
      providers.push({
        id: MicrosoftLoginProvider.PROVIDER_ID,
        provider: new MicrosoftLoginProvider(res.authMicrosoft.clientId, {
          authority: environment.social.microsoft.authorityUrl + res.authMicrosoft.tenantId,
          redirect_uri: this.getRedirectUri(res.authSubdomain),
          logout_redirect_uri: environment.social.microsoft.logoutUrl,
        }),
      });
    }

    if (res?.authGoogle.isEnabled && res?.authGoogle.clientId) {
      providers.push({
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(res.authGoogle.clientId, {
          oneTapEnabled: false,
        }),
      });
    }

    return {
      autoLogin: false,
      providers,
      onError: (err) => {
        throw new Error(err);
      },
    } as SocialAuthServiceConfig;
  }

  private getRedirectUri(authSubdomain: string): string {
    return 'https://' + (authSubdomain ? `${authSubdomain}.` : '') + environment.domain;
  }
}
