import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { AlertI18n, AlertProviderOptions, AlertType } from '../dto';

const prefix = '[Alert]';

export const ALERT_I18N = new InjectionToken<AlertI18n>(`${prefix} i18n`);
export const ALERT_OUTLINE = new InjectionToken<boolean>(`${prefix} outline`);
export const ALERT_TYPE = new InjectionToken<AlertType>(`${prefix} type`);

export const provideAlert = (options: AlertProviderOptions): EnvironmentProviders => {
  return makeEnvironmentProviders([
    {
      provide: ALERT_I18N,
      useValue: options.i18n,
    },
    {
      provide: ALERT_OUTLINE,
      useValue: typeof options.outline === 'undefined' ? true : options.outline,
    },
    {
      provide: ALERT_TYPE,
      useValue: options.type || 'info',
    },
  ]);
};
