import { Directive } from '@angular/core';
import { environment } from '@env/environment';
import { NgxTranslocoRevealService } from '../services/transloco-reveal.service';

@Directive({
  selector: '[appTranslocoReveal]',
  standalone: true,
})
export class TranslocoRevealDirective {
  constructor(private translateReveal: NgxTranslocoRevealService) {
    environment.translations.reveal && this.translateReveal.enable();
  }
}
