import { Routes } from '@angular/router';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { BREADCRUMB_KEY } from '@ui';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { BREADCRUMBS_GQL } from '@app/shared/constants/breadcrumb-gql.constants';

const section: PageSection[] = ['settings', 'account', 'users'];

export const settingsAccountUserRolesRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section: section,
      action: ['list'],
    }),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    loadComponent: () => import('./settings-account-users-roles-list/settings-account-users-roles-list.component'),
  },
  {
    path: ':id',
    title: titleResolverFactory({
      section: section,
      action: ['detail'],
      title: 'id',
    }),
    data: {
      [BREADCRUMB_KEY]: null,
    },
    children: [
      {
        path: '',
        data: {
          [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'id', gqlOptions: BREADCRUMBS_GQL.roles }),
        },
        loadComponent: () =>
          import('./settings-account-users-roles-detail/settings-account-users-roles-detail.component'),
      },
    ],
  },
];
