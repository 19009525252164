import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MeGQL } from '@graphql/query/root/generated/me.apollo';
import { meGuard } from '../rxjs/me-guard';

export const isLoggedGuard: CanActivateFn = () => {
  const router = inject(Router);
  const me = inject(MeGQL);
  return me.fetch({}, { fetchPolicy: 'network-only' }).pipe(meGuard(router, 'isLogged'));
};
