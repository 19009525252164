import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { providePaginationInfo } from '@ui';

export const PAGINATION_INFO_PROVIDER = providePaginationInfo({
  i18n: {
    $translate: true,
    zero: _('ui.pagination-info.zero'),
    one: _('ui.pagination-info.one'),
    two: _('ui.pagination-info.two'),
    three: _('ui.pagination-info.three'),
    four: _('ui.pagination-info.four'),
    many: _('ui.pagination-info.many'),
    other: _('ui.pagination-info.other'),
    loading: _('uni.loading'),
  },
});
