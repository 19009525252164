import { HttpHeaders } from '@angular/common/http';
import { ApplicationConfig, inject } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { TranslocoService } from '@jsverse/transloco';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { ApolloUtils } from './shared/utils/apollo.utils';
import { AuthUtils } from './shared/utils/auth.utils';

export function apolloOptionsFactory(): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink);
  const transloco = inject(TranslocoService);

  return {
    link: ApolloUtils.tokenRefreshLink
      .concat(ApolloUtils.authLink(transloco))
      .concat(ApolloUtils.sentryLink)
      .concat(ApolloUtils.retryLink)
      .concat(ApolloUtils.errorLink)
      .concat(
        httpLink.create({
          uri: environment.graphql.uri,
          withCredentials: true,
          headers: new HttpHeaders({
            Authorization: AuthUtils.basicAuthHeader,
          }),
        }),
      ),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
      },
      // query: {
      //   fetchPolicy: 'cache-first',
      //   errorPolicy: 'all',
      // },
    },
  };
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
  },
];
