import { Observable } from 'rxjs';
import { InputFile } from '../dto';

export abstract class AbstractFileUploader<T> {
  public abstract upload(files: File[]): Observable<T[]>;

  public abstract handleUnexpected(): void;

  public abstract handleEmpty(): void;

  public abstract handleTooLarge(corruptedFiles: string[]): void;

  public abstract handleInvalidFormat(corruptedFiles: string[]): void;

  public abstract handleSuccess(files: T[], idList: Map<string, InputFile>): void;
}
